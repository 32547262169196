<template>
  <div>

    <div class="row" v-permission="['user.create','user.edit']">
      <div class="col-md-12">



        <KTPortlet
            v-bind="{
            }"
            v-bind:class="{ 'kt-spinner-f': loading }"
        >
          <template v-slot:title>
            <h4 class="">Configurazione</h4>
          </template>
          <template v-slot:body>
            <!--                        <b-form-group-->
            <!--                                label-cols="4"-->
            <!--                                label-cols-lg="2"-->
            <!--                                label-size="sm"-->
            <!--                                label="Small"-->
            <!--                                label-for="input-sm"-->
            <!--                        >-->
            <!--                            <b-form-input id="input-sm" size="sm"></b-form-input>-->
            <!--                        </b-form-group>-->
            <b-form @submit="onSubmit" @reset="onReset">
              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="Nome"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.name"
                    type="text"
                    required
                    placeholder="Inserisci il Nome"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="Codice IREN"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.iren_code"
                    type="text"
                    required
                    placeholder="Inserisci il Codice IREN"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="Codice Instant Call"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.instant_call_code"
                    type="text"
                    required
                    placeholder="Inserisci il codice Instant Call"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="Codice CRM"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.eva_code"
                    type="text"
                    required
                    placeholder="Inserisci il codice CRM"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="Tipo"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.type"
                    type="text"
                    required
                    placeholder="Inserisci il tipo"
                ></b-form-input>
              </b-form-group>


<!--              <b-form-group-->
<!--                  label-cols="4"-->
<!--                  label-cols-lg="2"-->
<!--                  label="Abilita interfaccia"-->
<!--                  label-for="label"-->
<!--              >-->
<!--                <b-form-checkbox value="1"  v-model="form.ui_enabled" class="mb-2 mr-sm-2 mb-sm-0">-->
<!--                </b-form-checkbox>-->
<!--              </b-form-group>-->


              <b-form-group
                  v-if="isNew() && $store.getters.currentUser.is_admin"
                  label-cols="4"
                  label-cols-lg="2"
                  label="ID Organizzazione"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.organization_id"
                    type="text"
                    required
                    placeholder="ID Organizzazione"
                ></b-form-input>
              </b-form-group>
              <b-button class="pull-right" type="submit" variant="primary">Salva</b-button>
              <b-button type="reset" variant="danger">Indietro</b-button>

            </b-form>
          </template>
        </KTPortlet>
        <!--end:: Widgets/Activity-->
      </div>

    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "../../../../store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import UserService from "../../../../common/user.service";
import ApiService from "../../../../common/api.service";
import Vue from "vue";

export default {
  data() {
    return {
      loading:false,
      formoptions:{
      },
      form: {
      },

    }
  },
  components: {
    KTPortlet
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Utenti", route: "/setting-organization/user/list" },
      { title: !this.isNew()?"Modifica utente instant call":'Nuovo utente instant call' }
    ]);
  },

  created () {
    this.initialize()
  },
  methods:{
    isNew: function(){
      return !this.currentEditId();
    },
    currentEditId: function(){
      return this.$router.currentRoute.params.id;
    },
    onSubmit(evt) {
      evt.preventDefault()
      //alert(JSON.stringify(this.form))
      this.loading=true;
      let datasend = this.form;
      datasend.fistname = this.form.firstname;
      let prams = {};
      if(this.form.organization_id){
        prams.organization_id = this.form.organization_id;
      }
      console.log(prams);
      ApiService.post('instantcall'+(this.isNew()?'':'/'+this.currentEditId()),datasend,{ params: prams})
          .then(({data}) => {
            if(this.isNew()){
              Vue.customNotifySuccess('Salvato');
              this.$router.push({ name: "setting-organization-user-edit", 'params':{'id':data.id} });
              this.loading = false;
              this.reload();
            }else {
              Vue.customNotifySuccess('Salvato');
              this.loading = false;
              this.reload();
            }

          })
          .catch(({response}) => {
            Vue.customNotifyError('Errore',response.data&&response.data.errors?response.data.errors.join(', '):'Si prega di riprovare');
          });
    },
    onReset(evt) {
      this.$router.go(-1);

    },
    initialize () {
      this.reload();
    },
    reload(){
      const that = this;
      if(!this.isNew()) {
        this.loading=true;
        ApiService.get('instantcall/'+this.currentEditId())
            .then(({data}) => {

              this.form = data;

              this.loading=false;
            })
            .catch(({response}) => {
              //context.commit(SET_ERROR, response.data.errors);
            });
      }
      if(this.isNew() && this.$route.query.organization_id){
        this.form.organization_id = this.$route.query.organization_id;
      }
    },
  }
};
</script>
